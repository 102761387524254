import { AlertProps } from '@mui/material/Alert'
import { AlertTitle, Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material'
import { ForwardRefExoticComponent, ReactNode, forwardRef } from 'react'

export const Alert: ForwardRefExoticComponent<AlertProps> = forwardRef(
  ({ title, description, children, ...rest }, ref) => {
    return (
      <MuiAlert ref={ref} {...rest}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {description}
        {children}
      </MuiAlert>
    )
  }
)
Alert.displayName = 'Alert'

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    filled: false
  }

  interface AlertProps
    extends Pick<MuiAlertProps, 'title' | 'severity' | 'icon' | 'action' | 'onClose'> {
    description?: ReactNode | string
    /**
     * Determines whether to align the icon to the top of the title and description text, or to center-align it.
     *
     * @note
     * This is for screen sizes `md` and above. On screens sizes below `md`, the icon will always be top-aligned.
     *
     * @default 'center'
     */
    iconAlignment?: 'center' | 'top'
  }
}
