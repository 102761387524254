import { CreateBatchResponseType } from '@app/code/src/types/flowcode.types'
import { CreateCodeArguments } from '@app/code/src/components/Homepage/CreateFlowcodeForm/CreateFlowcodeFormDesktop/CreateFlowcodeFormDesktop.hooks'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import { FlowResponse } from '@app/code/src/types/flowFetch'
import { SIGN_UP_AND_CREATE_COOKIE_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { ServerSideError, ServerSideErrorType, logger } from '@dtx-company/logger'
import { SignupProps } from './useSignup'
import { flowFetch } from '@app/code/src/utils/flowFetch'
import { getCreatedCodeHeapProperties } from '@app/code/src/utils/flowcodeHeapProperties'
import { getFlowcodeId } from '@app/code/src/utils/flowcode'
import { notifyObserversOfSignup } from '@dtx-company/ithaca-sdk/src/frontegg/notify'
import { setAccessTokenCookie } from '@dtx-company/ithaca-sdk/src/frontegg/setAccessTokenCookie'
import events from '../../event-tracking/events/flowcode'

type SignUpSignInAndCreateCodReturnType = {
  accessToken: string
  createBatchResponse: CreateBatchResponseType
}

export const signUpAndCreateCode = async (
  signUpArg: SignupProps,
  createCodeArgs: CreateCodeArguments
): Promise<FlowResponse<SignUpSignInAndCreateCodReturnType> | undefined> => {
  const skipInviteEmail = !signUpArg.isEmailVerificationEnabled
  const resp = await flowFetch<SignUpSignInAndCreateCodReturnType>(
    'POST',
    SIGN_UP_AND_CREATE_COOKIE_ENDPOINT,
    {
      technicalArea: 'authentication'
    },
    {
      body: JSON.stringify({
        signUp: {
          ...signUpArg,
          cohort: signUpArg.cohortName,
          skipInviteEmail,
          signupLocation: 'loggedOutCodeGenerator'
        },
        createCodeArgs: createCodeArgs
      })
    }
  )
  if (resp?.data && resp.data.accessToken && resp.data.createBatchResponse.shortUrl) {
    const heapProperties = getCreatedCodeHeapProperties({
      isAutoDesign: Boolean(createCodeArgs.isAutoDesign),
      selectedFlowcodeTemplate: createCodeArgs.template,
      flowcodeTheme: createCodeArgs.theme,
      centerImage: Boolean(createCodeArgs.centerImageObjectURL),
      selectedDestination: createCodeArgs.selectedDestination.id,
      flowcodeOptions: createCodeArgs.options
    })
    const flowcodeId = getFlowcodeId(resp.data.createBatchResponse.shortUrl)
    events.userCreatedFlowcode(false, flowcodeId, heapProperties)

    await setAccessTokenCookie(resp.data.accessToken)
    notifyObserversOfSignup(resp.data.accessToken, Domains.FLOWCODE, 'loggedOutCodeGenerator')
  } else {
    const error = new ServerSideError({
      name: 'signUpSignInAndCreateCode',
      message: 'Failed to sign up, sign in and create code. Response data is invalid',
      type: ServerSideErrorType.ServerSideValidationError,
      endpoint: SIGN_UP_AND_CREATE_COOKIE_ENDPOINT
    })
    logger.logError(error, { technicalArea: 'authentication' })
    throw error
  }

  return resp
}
