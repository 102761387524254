import { Domains } from '@dtx-company/true-common/src/types/domains'
import { OnAuthFormSubmitArgs } from './index'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { logAndUnsubscribe } from './logAndUnsubscribe'
import { useAuthFormContext } from '../AuthenticationForm'
import { useCallback } from 'react'
import { useCohort } from '../../../hooks/cohorts/useCohort'
import { useCurrentPage } from '../../../redux/slices/utils'
import { useLogin } from '../../../hooks/auth/useLogin'
import { useSignup } from '../../../hooks/auth/useSignup'
import useTranslation from 'next-translate/useTranslation'

export const useSignUpOrSignIn = (): ((arg: OnAuthFormSubmitArgs) => Promise<void>) => {
  const { action } = useAuthFormContext()
  const isSignIn = action === 'sign in'
  const signupMethod = useSignup()
  const loginMethod = useLogin()
  const currentPage = useCurrentPage()
  const slug = currentPage?.slugName || ''
  const { t } = useTranslation('authentication')
  const { name: cohortName } = useCohort()

  return useCallback(
    async ({ values, onSubmit }: OnAuthFormSubmitArgs) => {
      let user

      if (isSignIn) {
        user = await loginMethod(values)
      } else {
        const product = slug ? Domains.FLOWPAGE : Domains.FLOWCODE
        user = await signupMethod({
          ...values,
          product
        })
      }
      if (!user) {
        throw new Error(t('AuthErrors.user'))
      }

      if (onSubmit) {
        await onSubmit(user, SocialProvider.PASSWORD, slug)
      }

      await logAndUnsubscribe({
        isSignIn,
        noMarketingEmails: values.noMarketingEmails,
        email: values.email,
        cohortName
      })
    },
    [cohortName, isSignIn, loginMethod, signupMethod, slug, t]
  )
}
